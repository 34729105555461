<template>
  <div>
    <black-overlay />
    <div class="home__container"  @click="welcomingTextHandler">
      <div class="welcoming__text">
        <div class="welcoming__text__el" v-html="welcomingText[index]"></div>
      </div>
      <div class="wecloming__emojy">
        <div class="wecloming__smile">
          <div v-if="bioButton.url && bioButton.text" id="wecloming__circle">
            <a :href="`${bioButton.url}`" download>
              <div class="wecloming__circle__text" v-html="bioButton.text"></div
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const BlackOverlay = () => import("../components/home/BlackOverlay");

export default {
  name: "Home",
  components: {
    BlackOverlay,
  },
  data() {
    return {
      welcomingText: [],
      bioButton: {},
      index: 0,
    };
  },
  mounted() {
    import(`../../public/json-db/home/data.json`).then((data) => {
      this.welcomingText = data.welcomingText;
      this.bioButton = data.bioButton;
    });
  },
  methods: {
    welcomingTextHandler() {
      this.index += 1;
      if (this.index > this.welcomingText.length - 1) {
        this.index = 0;
      }
    },
  },
};
</script>
