<template>
  <div class="work__container">
    <router-link
      to="/works"
      style="
        left: 5px;
        top: 5px;
        position: absolute;
        z-index: 100;
        cursor: pointer;
      "
    >
      <img
        style="
          background-color: black;
          border: solid 0.5px black;
          border-radius: 50px;
        "
        :src="backIcon"
      />
    </router-link>
    <div class="work__inside__container">
      <div class="work__inside__images_wrapper">
        <div class="work__inside__images" v-if="basicData.images.length > 0">
          <div
            v-for="(img, index) in basicData.images"
            v-bind:key="index"
            class="work__inside__images_image__wrapper"
            :id="`work__inside__images_image__wrapper__${img.id}`"
            @click="recalculateDepth(img.id, img.type, 'element')"
            :style="{
              zIndex: index,
              opacity: index == basicData.images.length - 1 ? 1 : 0.5,
            }"
          >
            <iframe
              v-if="img.type && img.type === 'videoLink'"
              width="420"
              height="440"
              :src="img.src"
            >
            </iframe>

            <video
              v-if="img.type && img.type === 'video'"
              width="420"
              height="440"
              controls
              :src="img.src"
              type="video/mp4"
            ></video>

            <img v-else :src="img.src" alt="" @load="isImgLoaded" />
            <div
              v-if="img.type && img.type === 'videoLink'"
              class="video_link_next_button"
            >
              <img
                @click="recalculateDepth(img.id, img.type, 'button')"
                height="25"
                width="25"
                :src="closeIcon"
              />
            </div>
          </div>
          <div
            v-if="imgLoaded !== imgCount || !imgDownloaded"
            class="work__images__loading__overlay"
          >
            <div class="work__images__loading__loader"></div>
          </div>
        </div>
      </div>
      <div class="work__inside__text_wrapper">
        <div class="work__inside__text">
          <div
            class="work__inside__text_title"
            v-html="basicData.text.title"
          ></div>
          <div class="work__inside__text_text">
            <p v-html="basicData.text.text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Work",
  data() {
    return {
      showOverlay: false,
      backIcon: "../assets/arrow_left_circle_white.png",
      nextIcon: "../assets/arrow_left_circle.png",
      closeIcon: "../assets/close_icon.jpg",
      basicData: {
        text: {
          title: "",
          text: "",
          aux: "",
        },
        images: [
          {
            src: "",
            id: "",
          },
        ],
      },
      imgLoaded: 0,
      imgDownloaded: false,
      imgCount: 0,
    };
  },
  mounted() {
    this.showOverlay = true;
    import(`../../public/json-db/works/${this.$route.params.name}/data.json`)
      .then((data) => {
        this.basicData = data.default;
        this.basicData.images.forEach((im) => {
          if (!im.type || im.type !== "videoLink") {
            this.imgCount += 1;
          }
        });
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 700);
      });
  },
  methods: {
    recalculateDepth(clickId, type, origin) {
      if (
        (type !== "videoLink" && type !== "video" && origin === "element") ||
        type === "videoLink" ||
        (type === "video" && origin === "button")
      ) {
        let activeIndex = 0;
        const imgCount = this.basicData.images.length;
        this.basicData.images.forEach((img, index) => {
          const el = document.querySelectorAll(
            `#work__inside__images_image__wrapper__${img.id}`
          )[0];
          if (img.id === clickId) {
            el.style.zIndex = "0";
            activeIndex = index;
            el.firstElementChild.style.zIndex = "0";
          } else {
            el.style.zIndex = `${Number(el.style.zIndex) + 1}`;
            el.firstElementChild.style.zIndex = `${
              Number(el.style.zIndex) + 1
            }`;
          }

          el.style.opacity = "0.5";
          el.style.pointerEvents = "none";

          el.firstElementChild.style.opacity = "0.5";
          el.firstElementChild.style.pointerEvents = "none";
        });
        if (activeIndex === 0) {
          const frontEl = document.querySelectorAll(
            `#work__inside__images_image__wrapper__${
              this.basicData.images[imgCount - 1].id
            }`
          )[0];
          frontEl.style.opacity = "1";
          frontEl.style.pointerEvents = "auto";

          frontEl.firstElementChild.style.opacity = "1";
          frontEl.firstElementChild.style.pointerEvents = "auto";
        } else {
          const frontEl = document.querySelectorAll(
            `#work__inside__images_image__wrapper__${
              this.basicData.images[activeIndex - 1].id
            }`
          )[0];

          frontEl.style.opacity = "1";
          frontEl.style.pointerEvents = "auto";

          frontEl.firstElementChild.style.opacity = "1";
          frontEl.firstElementChild.style.pointerEvents = "auto";
        }
      }
    },
    isImgLoaded() {
      this.imgLoaded += 1;

      if (this.imgLoaded === this.imgCount) {
        setTimeout(() => {
          this.imgDownloaded = true;
        }, 600);
      }
    },
  },
};
</script>
