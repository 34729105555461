import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Works from "../views/Works.vue";
import Work from "../views/Work.vue";
import Stuff from "../views/Stuff.vue";
import Contact from "../views/Contact.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    codeName: "home",
    component: Home,
  },
  {
    path: "/works",
    name: "Works",
    codeName: "works",
    component: Works,
  },
  // {
  //   path: "/stuff",
  //   name: "Stuff",
  //   codeName: "stuff",
  //   component: Stuff,
  // },
  {
    path: "/contact",
    name: "Contact",
    codeName: "contact",
    component: Contact,
  },
  {
    path: "/works/:name",
    name: "Work",
    codeName: "work",
    component: Work,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
