<template>
  <div class="stuff__container">
    <div class="stuff__images__wrapper">
      <div
        class="stuff__img__row"
        v-for="(item, index) in items"
        :key="index"
        :style="{ height: `${item[0].height}px` }"
      >
        <div
          class="stuff__img__block"
          v-for="(img, index) in item"
          :key="index"
          :style="{
            top: img.top,
            bottom: img.bottom,
            left: img.left,
            right: img.right,
          }"
        >
          <div class="stuff__img__wrapper">
            <div
              v-if="img.type === 'image'"
              :class="img.showImage_onHover ? 'show__img__on__hover' : ''"
            >
              <img
                :style="{
                  zIndex: img.zIndex,
                }"
                class=""
                :height="img.height"
                :src="img.src"
                alt=""
              />
            </div>

            <div
              v-if="img.type === 'text'"
              :style="{
                zIndex: img.zIndex,
                height: img.height
              }"
              :class="
                img.twoColumns
                  ? 'two__columns text__in__front__of__image'
                  : 'text__in__front__of__image'
              "
              v-html="`${img.text}`"
            ></div>
            <div
              v-if="img.type === 'videoLink'"
              :style="{
                zIndex: img.zIndex,
              }"
              :class="
                img.twoColumns
                  ? 'two__columns text__in__front__of__image'
                  : 'text__in__front__of__image'
              "
            >
              <iframe width="420" height="315" :src="img.src"> </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Stuff",
  data() {
    return {
      items: [],
      itemsClasses: {},
    };
  },
  mounted() {
    import(`../../public/json-db/stuff/data.json`).then((data) => {
      this.items = data.items;
    });
  },
  methods: {
    handleMouseover(key) {
      this.itemsClasses[key] = "on__hover";
    },
    handleMouseout(key) {
      this.itemsClasses[key] = "";
    },
  },
};
</script>
