var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact__container"},[_c('div',{staticClass:"left__container"},[_c('div',{staticClass:"email__contact"},[_c('div',{staticClass:"email__sender"},[_c('div',{staticClass:"email__first"},[_vm._v("Look at the cube")]),_c('div',{staticClass:"email__second"},[_vm._v("or")]),_c('div',{staticClass:"email__third"},[_vm._v("send me a message")]),_c('div',{staticClass:"email__input"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"email__from__input"},[_c('label',{attrs:{"for":"email_from"}},[_vm._v("From")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender),expression:"sender"}],style:({
                  border:
                    this.error && this.sender === ''
                      ? 'solid 2px red'
                      : ' dashed 2px black',
                }),attrs:{"type":"text","name":"email_from"},domProps:{"value":(_vm.sender)},on:{"input":function($event){if($event.target.composing){ return; }_vm.sender=$event.target.value}}})]),_c('div',{staticClass:"email__subject__input"},[_c('label',{attrs:{"for":"email_subject"}},[_vm._v("Subject")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subject),expression:"subject"}],style:({
                  border:
                    this.error && this.subject === ''
                      ? 'solid 2px red'
                      : ' dashed 2px black',
                }),attrs:{"type":"text","name":"email_subject"},domProps:{"value":(_vm.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.subject=$event.target.value}}})])]),_c('div',{staticClass:"email__textarea"},[_c('label',{attrs:{"for":"email_message"}},[_vm._v("Message")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],style:({
                border:
                  this.error && this.message === ''
                    ? 'solid 2px red'
                    : ' dashed 2px black',
              }),attrs:{"rows":"8","name":"email_message"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}})])]),_c('div',{style:({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          })},[_c('div',{style:({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: 'red',
            })},[(_vm.notSend)?_c('div',{style:({ fontSize: '10px', color: 'red' })},[_vm._v(" Something went wrong ")]):_vm._e()]),_c('div',{staticClass:"email__button__wrapper"},[_c('button',{staticClass:"email__button",on:{"click":_vm.sendMail}},[_vm._v("Submit")])])])])])]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right__container"},[_c('div',{staticClass:"cube__container"},[_c('div',{staticClass:"cube"},[_c('div',{staticClass:"face front"}),_c('div',{staticClass:"face back"}),_c('div',{staticClass:"face right"}),_c('div',{staticClass:"face left"}),_c('div',{staticClass:"face top"}),_c('div',{staticClass:"face bottom"})])])])}]

export { render, staticRenderFns }