<template>
  <nav
    class="navbar__container"
    :style="{
      'background-color': color,
      color: textColor,
    }"
  >
    <div class="menu_item_tow">
      <div class="navbar__slick">
        <div
          v-for="index in slickTextClones"
          :key="index"
          :class="`navbar__slick__item  navbar__slick__item-${index}`"
        >
          <div v-html="slickText"></div>
        </div>
      </div>
    </div>
    <div class="menu_item_one" v-if="categories.length > 0">
      <div class="navbar__menu" :style="{ 'background-color': color }">
        <router-link :to="categories[0].url">
          <div
            class="navbar__menu__item"
            :style="{
              color: textColor,
              'border-color': textColor,
              'border-style': borderStyle,
            }"
          >
            {{ categories[0].title }}
          </div></router-link
        ><router-link :to="categories[1].url">
          <div
            class="navbar__menu__item navbar__menu__item-middle"
            :style="{
              color: textColor,
              'border-color': textColor,
              'border-style': borderStyle,
            }"
          >
            {{ categories[1].title }}
          </div></router-link
        >
        <!-- <router-link :to="categories[2].url">
          <div
            class="navbar__menu__item"
            :style="{
              color: textColor,
              'border-color': textColor,
              'border-style': borderStyleLast,
            }"
          >
            {{ categories[2].title }}
          </div></router-link
        > -->
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "Navbar",
  mounted() {
    import(`../../../public/json-db/navbar/data.json`).then((data) => {
      this.categories = data.categories;
      this.categoriesInitial = data.categoriesInitial;
      this.colorSwitch(this.$route.name);
      this.titleSwitch(this.$route.name);
    });
  },
  data() {
    return {
      color: "#FD8642",
      textColor: "black",
      borderStyle: "solid solid solid solid",
      borderStyleLast: "solid solid solid solid",
      navbarBorder: "none",
      categories: [],
      categoriesInitial: {},
      slickText: "",
      slickTextClones: 0,
    };
  },
  methods: {
    colorSwitch(pathName) {
      switch (pathName) {
        case "Home":
          this.color = "#FD8642";
          this.textColor = "black";
          this.borderStyle = "solid";
          this.borderStyleLast = "solid";
          this.navbarBorder = "none";
          break;
        case "Works":
          this.color = "#110DD7";
          this.textColor = "white";
          this.borderStyle = "solid";
          this.borderStyleLast = "solid";

          this.navbarBorder = "none";
          break;
        case "Work":
          this.color = "#110DD7";
          this.textColor = "white";
          this.borderStyle = "solid";
          this.borderStyleLast = "solid";

          this.navbarBorder = "none";
          break;
        // case "Stuff":
        //   this.color = "#000000";
        //   this.textColor = "white";
        //   this.borderStyle = "solid";
        //   this.borderStyleLast = "solid";
        //   this.navbarBorder = "none";
        //   break;
        case "Contact":
          this.color = "#F48BCA";
          this.textColor = "black";
          this.borderStyle = "solid";
          this.borderStyleLast = "solid";
          this.navbarBorder = "solid 2px black";
          break;
        default:
          this.color = "#FD8642";
          this.textColor = "black";
          this.borderStyle = "solid";
          this.borderStyleLast = "solid";
          this.navbarBorder = "none";
          break;
      }
    },
    titleSwitch(pathName) {
      switch (pathName) {
        case "Home":
          this.categories[0] = this.categoriesInitial.works;
          this.categories[1] = this.categoriesInitial.contact;
          // this.categories[2] = this.categoriesInitial.contact;
          this.slickText = this.categoriesInitial.startAgain.slickText;
          this.slickTextClones =
            this.categoriesInitial.startAgain.slickTextClones;
          break;
        case "Works":
          this.categories[0] = this.categoriesInitial.startAgain;
          this.categories[1] = this.categoriesInitial.contact;
          // this.categories[2] = this.categoriesInitial.contact;
          this.slickText = this.categoriesInitial.works.slickText;
          this.slickTextClones = this.categoriesInitial.works.slickTextClones;
          break;
        // case "Stuff":
        //   this.categories[0] = this.categoriesInitial.startAgain;
        //   this.categories[1] = this.categoriesInitial.contact;
        //   // this.categories[2] = this.categoriesInitial.contact;
        //   this.slickText = this.categoriesInitial.stuff.slickText;
        //   this.slickTextClones = this.categoriesInitial.stuff.slickTextClones;
        //   break;
        case "Contact":
          this.categories[0] = this.categoriesInitial.startAgain;
          this.categories[1] = this.categoriesInitial.works;
          // this.categories[2] = this.categoriesInitial.stuff;
          this.slickText = this.categoriesInitial.contact.slickText;
          this.slickTextClones = this.categoriesInitial.contact.slickTextClones;
          break;
        case "Work":
          this.categories[0] = this.categoriesInitial.startAgain;
          this.categories[1] = this.categoriesInitial.contact;
          // this.categories[2] = this.categoriesInitial.contact;
          this.slickText = this.categoriesInitial.work.slickText;
          this.slickTextClones = this.categoriesInitial.work.slickTextClones;
          break;
        default:
          this.categories[0] = this.categoriesInitial.works;
          this.categories[1] = this.categoriesInitial.contact;
          // this.categories[2] = this.categoriesInitial.contact;
          this.slickText = this.categoriesInitial.startAgain.slickText;
          this.slickTextClones =
            this.categoriesInitial.startAgain.slickTextClones;
          break;
      }
    },
  },
  watch: {
    $route: function (to) {
      this.colorSwitch(to.name);
      this.titleSwitch(to.name);
    },
  },
};
</script>
