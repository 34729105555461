<template>
  <div id="app">
    <navbar></navbar>
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/menu/Navbar.vue";
export default {
  components: {
    Navbar,
  },
};
</script>