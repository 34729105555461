var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stuff__container"},[_c('div',{staticClass:"stuff__images__wrapper"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"stuff__img__row",style:({ height: ((item[0].height) + "px") })},_vm._l((item),function(img,index){return _c('div',{key:index,staticClass:"stuff__img__block",style:({
          top: img.top,
          bottom: img.bottom,
          left: img.left,
          right: img.right,
        })},[_c('div',{staticClass:"stuff__img__wrapper"},[(img.type === 'image')?_c('div',{class:img.showImage_onHover ? 'show__img__on__hover' : ''},[_c('img',{style:({
                zIndex: img.zIndex,
              }),attrs:{"height":img.height,"src":img.src,"alt":""}})]):_vm._e(),(img.type === 'text')?_c('div',{class:img.twoColumns
                ? 'two__columns text__in__front__of__image'
                : 'text__in__front__of__image',style:({
              zIndex: img.zIndex,
              height: img.height
            }),domProps:{"innerHTML":_vm._s(("" + (img.text)))}}):_vm._e(),(img.type === 'videoLink')?_c('div',{class:img.twoColumns
                ? 'two__columns text__in__front__of__image'
                : 'text__in__front__of__image',style:({
              zIndex: img.zIndex,
            })},[_c('iframe',{attrs:{"width":"420","height":"315","src":img.src}})]):_vm._e()])])}),0)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }