<template>
  <div class="works__container" v-if="images.length > 0">
    <div class="cursor_on_image"></div>
    <div class="works__images__container">
      <div
        v-for="(imageCol, indexCol) in images"
        :key="indexCol"
        class="works__image__wrapper__column"
      >
        <div
          v-for="(image, index) in imageCol"
          :key="index"
          class="works__image__wrapper"
        >
          <img
            :id="`image__id__${index}`"
            :class="handeImageClass(`image__id__${index}`)"
            :src="image.src"
            alt=""
            @click="goToWork(image.goTo)"
            @load="isImgLoaded"
          />
          <div :class="`image__id__${index} image__caption`">
            {{ image.caption }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Works",
  data() {
    return { images: [], imgLoaded: 0, imgDownloaded: false };
  },
  mounted() {
    import("../../public/json-db/works/data.json")
      .then((data) => {
        var colN = 4;
        data.images.forEach((im, i) => {
          if (i % colN in this.images) {
            this.images[i % colN].push(im);
          } else {
            this.images.push([im]);
          }
        });
      })
      .then(() => {
        let customCursor = document.createElement("script");
        customCursor.setAttribute("src", "/customCursor.js");
        customCursor.setAttribute("id", "custom_corsor_script");

        document.head.appendChild(customCursor);
        setTimeout(() => {
          this.imgDownloaded = true;
        }, 1500);
      });
  },
  beforeDestroy() {
    var customCursor = document.getElementById("custom_corsor_script");
    customCursor.remove();
  },
  methods: {
    handeImageClass(imgId) {
      const image = document.querySelectorAll(`#${imgId}`)[0];
      if (image?.clientHeight >= image?.clientWidth) {
        return "works__image__vertical works__gallery__image";
      }
      return "works__image__horizontal works__gallery__image";
    },
    goToWork(path) {
      this.$router.push({ path });
    },
    isImgLoaded() {
      this.imgLoaded += 1;
    },
  },
};
</script>
